import React from 'react';
import './AboutUs.css'; // Import your CSS file for styling if needed
import aboutImage from '../Assets/AboutUs/manoj_dave.jpg'; // Import your image
import teamImage from '../Assets/AboutUs/team.jpg'; // Import your image
const AboutUs = () => {
    return (
        <div className="about-us-container">
            {/* Quote Section */}
            <section className="quote-section">
                <div className="quote">
                    <p>"Crafting spaces that are simple, soulful and sophisticated."</p>
                   
                </div>
            </section>

            {/* Main Content Section */}
            <section className="main-content">
                {/* Left Side - Information */}
                <div className="info">
                    <h2>About Us</h2>
                    <p>
                    At Gibigiaana, innovative design meets exceptional craftsmanship. Founded by Mr. Manoj Dave, we specialize in transforming spaces into beautiful, functional environments that reflect your unique style and needs. We believe that every space has its own story. Whether it's the cozy comfort of your home or the impressive elegance of your exterior facade, we ensure every space feels perfectly suited to you.
                    </p>
                </div>

                {/* Right Side - Image */}
                <div className="image">
                    <img src={aboutImage} alt="About Us" />
                </div>
            </section>
            <div className="stats-team-container">
      <div className="stats">
        <div className="stat-item">
          <h1>25+</h1>
          <p>YEARS OF EXPERIENCE</p>
          <p>We bring passion and professionalism to every detail, transforming your house into a beautifully furnished home.</p>
        </div>
        <div className="stat-item">
          <h1>PAN India</h1> 
          <p>PRESENCE</p>
          <p>We have a strong presence across the nation, having completed projects in major cities such as Goa, Punjab, Bangalore, and Maharashtra. Our reach is continually expanding, with upcoming projects extending internationally to Dubai as well.</p>
        </div>
      </div>
      <section className="main-content1">
      <div className="image1">
                    <img src={teamImage} alt="Our Team" />
                </div>
                {/* Left Side - Information */}
                <div className="info1">
                    <h2>Our Team</h2>
                    <p>
                    Our team of enthusiastic and innovative interior designers is devoted to turning spaces into breathtaking and practical settings. With meticulous attention to detail and an unwavering commitment to excellence, we make your vision a reality.                    </p>
                </div>

                {/* Right Side - Image */}
                
            </section>
    </div>
        </div>
    );
}

export default AboutUs;
