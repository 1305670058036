import React, { useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import video from './landing_page_video.mp4';
import logo from './logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/home' || location.pathname === '/';

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={`navbar-container ${!isHomePage ? 'no-video' : ''} ${isMobileMenuOpen ? 'expanded' : ''}`} id='navbar'>
      {isHomePage && (
        <video autoPlay muted loop id="background-video">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <img src={logo} alt="logo" className="logo" />

      <div className='navbar'>
        <div className="hamburger" onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className={`nav ${isMobileMenuOpen ? 'open' : ''}`} id="navLinks">
          <ul>
            <li><Link to="/home" onClick={toggleMobileMenu}>Home</Link></li>
            <li><Link to="/about-us" onClick={toggleMobileMenu}>About Us</Link></li>
            <li><Link to="/services" onClick={toggleMobileMenu}>Services</Link></li>
            <li className="dropdown">
              <Link to="/" onClick={toggleMobileMenu}>Creations</Link>
              <div className="dropdown-content">
                <Link to="/interior" onClick={toggleMobileMenu}>Interior</Link>
                <Link to="/architecture" onClick={toggleMobileMenu}>Architecture</Link>
              </div>
            </li>
            <li><Link to="/contacts" onClick={toggleMobileMenu}>Contacts</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
