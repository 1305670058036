import React, { useState } from 'react';
import image1 from '../Assets/Interior_creations/office/image1.jpg';
import image2 from '../Assets/Interior_creations/apartments/image1.jpg';
import image3 from '../Assets/Interior_creations/office/image2.jpg';
import image4 from '../Assets/Interior_creations/apartments/image2.jpg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import image5 from '../Assets/Interior_creations/office/image5.jpg';
import image6 from '../Assets/Interior_creations/office/image6.jpg';
import image7 from '../Assets/Interior_creations/office/image7.jpg';
import image8 from '../Assets/Interior_creations/office/image8.jpg';
import image14 from '../Assets/Interior_creations/office/image14.jpg';
import image15 from '../Assets/Interior_creations/office/image15.jpg';
import image17 from '../Assets/Interior_creations/office/image17.jpg';
import image18 from '../Assets/Interior_creations/office/image18.jpg';
import image19 from '../Assets/Interior_creations/office/image19.jpg';
import image20 from '../Assets/Interior_creations/office/image20.jpg';
import image24 from '../Assets/Interior_creations/office/image24.jpg';
import image11 from '../Assets/Interior_creations/apartments/image5.jpg';
import image12 from '../Assets/Interior_creations/apartments/image14.jpg';
import image13 from '../Assets/Interior_creations/apartments/image15.jpg';
import image10 from '../Assets/Interior_creations/apartments/image8.jpg';
import image9 from '../Assets/Interior_creations/apartments/image3.jpg';
import './Interior_creations.css';

// Header component
const Header = () => (
  <header className="header">
    <h1>Timeless Interiors</h1>
    <p>We take pride in transforming spaces into beautiful, functional, and inspiring environments. Each project showcased here is a testament to our dedication and passion. We invite you to explore our gallery and discover the magic created when innovative design and exceptional execution come together.</p>
  </header>
);

// ImageCard component
const ImageCard = ({ image, title, onClick }) => (
  <div className="card" onClick={onClick}>
    <img src={image} alt={title} className="card-image" />
    <div className="card-title">{title}</div>
  </div>
);

// ImageGrid component
const imagesCommercial = [
  { original: image1, thumbnail: image1 },
  { original: image3, thumbnail: image3 },
  { original: image5, thumbnail: image5 },
  { original: image6, thumbnail: image6 },
  { original: image7, thumbnail: image7 },
  { original: image8, thumbnail: image8 },
  { original: image14, thumbnail: image14 },
  { original: image15, thumbnail: image15 },
  { original: image17, thumbnail: image17 },
  { original: image18, thumbnail: image18 },
  { original: image19, thumbnail: image19 },
  { original: image20, thumbnail: image20 },
  { original: image24, thumbnail: image24 },
];

const imagesApartments = [
  { original: image2, thumbnail: image2 },
  { original: image4, thumbnail: image4 },
  { original: image11, thumbnail: image11 },
  { original: image12, thumbnail: image12 },
  { original: image13, thumbnail: image13 },
  { original: image10, thumbnail: image10 },
  { original: image9, thumbnail: image9 },
];

const ImageGrid = ({ onImageClick }) => (
  <div className="image-grid">
    <ImageCard
      image={image1}
      title='Commercial Sites'
      onClick={() => onImageClick('commercial')}
    />
    <ImageCard
      image={image2}
      title='Apartments'
      onClick={() => onImageClick('apartments')}
    />
  </div>
);

// Main component
const InteriorCreation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);

  const openLightbox = (gallery) => {
    setSelectedGallery(gallery);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setSelectedGallery(null);
  };

  return (
    <div>
      <Header />
      <div className="message-style"><h2 className="blinking-border">Click on the image to view the Gallery</h2></div>
      <ImageGrid onImageClick={openLightbox} />
      {isOpen && (
        <div className="image-gallery-modal">
          <div className="image-gallery-container">
            <button className="image-gallery-close" onClick={closeLightbox}>×</button>
            {selectedGallery === 'commercial' && (
              <ImageGallery items={imagesCommercial} />
            )}
            {selectedGallery === 'apartments' && (
              <ImageGallery items={imagesApartments} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InteriorCreation;
