import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Footer from './Components/Footer/Footer';
import Interior from './Pages/Interior_creations';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import Service from './Pages/Service';
import Navbar from './Components/Navbar/Navbar';
import ArchitectureCreations from './Pages/Architecture_creations';
function App() {
  return (
    <Router>
<Navbar/>
<Routes>
          <Route exact path="/services" element={<Service />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/interior" element={<Interior />} />
          <Route path="/architecture" element={<ArchitectureCreations />} />
       
          <Route path="/" element={<Home />} />
         
        </Routes>
    
        <Footer />
      
    </Router>
  );
}


export default App;
