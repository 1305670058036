import React from 'react';
import './Footer.css'; // Make sure this CSS file is created and linked
import { ImWhatsapp } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';
import logo from './logo.png';
const Footer = () => {
    return (
        <>
        <div className="home-container">
      <div className="section">
		<Link to="/about-us">
        <div className="section-content">
          <p className="no-underline">GET TO KNOW US!</p>
          <p className="no-underline">ABOUT US</p>
        </div>
		</Link>

      </div>
	  
      <div className="section">
	  <Link to="/contacts">

        <div className="section-content1">
          <p className="no-underline">REQUEST INFORMATION</p>
          <p className="no-underline">CONTACT US</p>
        </div>
		</Link>
      </div>
      
    </div>
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <div className="footer-logo">
                        <img src={logo} alt="Gibigiaana Logo" />
                    </div>
                    <address>
                        <p>5th Floor, Office No. 520/521, 174 Gold Mohur CHS Ltd, Shamaldas Gandhi Marg, Princess Street, Above Indian Art Studio, Marine lines(E), Mumbai 400002.</p>
                        <p>📞 (+91) 93243 48593</p>
                        <p>WhatsApp: (+91)98205 33571</p>
                        <p>📧 <a href="gibigiaana@gmail.com">gibigiaana@gmail.com</a></p>
                    </address>
                </div>
                <div className="footer-center">
                    <h3>OPENING HOURS</h3>
                    <p><strong>Monday - Saturday</strong></p>
                    <p>10.30 am / 7.00pm</p>
                    <p><strong>Closed: Sunday </strong> </p>
                </div>
                <div className="footer-right">
                    <div className="footer-social">
                        <a href="https://wa.me/9820533571"><ImWhatsapp /></a>
                        <a href="https://www.instagram.com"><FaInstagram /></a>
                        <a href="https://www.linkedin.com"><FaLinkedin /></a>
                        
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
};

export default Footer;
