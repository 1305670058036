import React, { useEffect, useState } from 'react';
import serviceImage from '../Assets/services_homepage.jpg';
import creationImage from '../Assets/creations_homepage.jpg';
import './Home.css';
import frontImage1 from '../Assets/before_image1.jpg';
import backImage1 from '../Assets/after_image1.jpg';
import frontImage2 from '../Assets/before_image2.jpg';
import backImage2 from '../Assets/after_image2.jpg';
import frontImage3 from '../Assets/before_image3.jpg';
import backImage3 from '../Assets/after_image3.jpg';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Home = () => {
    const [animateService, setAnimateService] = useState(false);
    const [animateCreations, setAnimateCreations] = useState(false);
    const [flippedCards, setFlippedCards] = useState({
        card1: false,
        card2: false,
        card3: false,
    });
    const navigate = useNavigate();

    const handleFlip = (card) => {
        setFlippedCards((prevState) => ({
            ...prevState,
            [card]: !prevState[card],
        }));
    };

    const handleScrollToTop = () => {
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const serviceSection = document.getElementById('service').offsetTop;
            const creationsSection = document.getElementById('creations').offsetTop;

            if (scrollPosition > serviceSection) {
                setAnimateService(true);
            }

            if (scrollPosition > creationsSection) {
                setAnimateCreations(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='info'>
            <section id="service" className="service">
                <div className={`service-info ${animateService ? 'animate-left' : ''}`}>
                    <h2>Our Services</h2>
                    <p>We offer a complete range of services to bring your vision to life. From initial briefing and bespoke designing to precise execution and seamless installation, we manage every step with care and expertise. Finally, we ensure a smooth handover, delivering a stunning, ready-to-enjoy space.</p>
                    <Link to="/services"><div className="arrow-right">→</div></Link>
                </div>
                <div className={`service-image ${animateService ? 'animate-right' : ''}`}>
                    <img src={serviceImage} alt="Service" />
                </div>
            </section>
            <section id="creations" className="creations">
                <div className={`creations-info ${animateCreations ? 'animate-right' : ''}`}>
                    <h2>OUR CREATIONS</h2>
                    <p>We specialize in crafting exquisite interiors and exteriors for apartments, residential and commercial buildings, resorts, and villas. Our portfolio includes top projects like Lodha, showcasing our commitment to exceptional design and quality. Each creation reflects our dedication to blending functionality with luxury, transforming spaces into inspiring environments.</p>
                    <div onClick={handleScrollToTop}><div className="arrow-right">→</div></div>
                </div>
                <div className={`creations-image ${animateCreations ? 'animate-left' : ''}`}>
                    <img src={creationImage} alt="Creations" />
                </div>
            </section>
            <h2 className="blinking-border">Explore interior changes through before and after pictures.</h2>
            <div className="container">
                <div className={`flip-card ${flippedCards.card1 ? "flipped" : ""}`}>
                    <div className="flip-card-inner1" onClick={() => handleFlip('card1')}>
                        <div className="flip-card-front" style={{ backgroundImage: `url(${frontImage1})` }}>
                            <div className="card-content">Before</div>
                        </div>
                        <div className="flip-card-back" style={{ backgroundImage: `url(${backImage1})` }}>
                            <div className="card-content">After</div>
                        </div>
                    </div>
                </div>
                <div className={`flip-card ${flippedCards.card2 ? "flipped" : ""}`}>
                    <div className="flip-card-inner2" onClick={() => handleFlip('card2')}>
                        <div className="flip-card-front" style={{ backgroundImage: `url(${frontImage2})` }}>
                            <div className="card-content">Before</div>
                        </div>
                        <div className="flip-card-back" style={{ backgroundImage: `url(${backImage2})` }}>
                            <div className="card-content">After</div>
                        </div>
                    </div>
                </div>
                <div className={`flip-card ${flippedCards.card3 ? "flipped" : ""}`}>
                    <div className="flip-card-inner3" onClick={() => handleFlip('card3')}>
                        <div className="flip-card-front" style={{ backgroundImage: `url(${frontImage3})` }}>
                            <div className="card-content">Before</div>
                        </div>
                        <div className="flip-card-back" style={{ backgroundImage: `url(${backImage3})` }}>
                            <div className="card-content">After</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
