import React from 'react';
import './Service.css';
import DesigningImage from '../Assets/Services/designing.jpg';
import CustomMadeProjects from '../Assets/Services/custom_made_projects.jpg';
import Rendering from '../Assets/Services/3D_rendering.jpg';
const ServicePage = () => {
  return (
    <>
      <div className="serviceContainer">
        <h1 className="serviceTitle">SERVICES</h1>
        
        <div className="serviceContent">
          <div className="serviceImage">
            <img src={DesigningImage} alt="Service" />
          </div>
          <div className="serviceInfo">
            <h2>Designing</h2>
            <p>From conceptualization to completion, our interior design services transform your living spaces into functional, aesthetically pleasing environments while our exterior design services enhance the curb appeal and functionality of your property.</p>
          </div>
        </div>
        
        <div className="serviceContent">
        <div className="serviceImage">
            <img src={Rendering} alt="Service"/>
          </div>
          <div className="serviceInfo">
            <h2>3D and Rendering</h2>
            <p>We bring your design visions to life with our cutting-edge 3D modeling and rendering services. Our advanced technology and skilled team enable you to visualize every detail of your project with stunning realism before any construction begins.</p>
          </div>
          
        </div>
        
        <div className="serviceContent">
          <div className="serviceImage">
            <img src={CustomMadeProjects} alt="Service" />
          </div>
          <div className="serviceInfo">
            <h2>Custom Made Projects</h2>
            <p>We understand that every client is unique, and so are their needs and preferences. Our Custom Made Projects service is designed to bring your one-of-a-kind vision to life with tailored solutions that reflect your personality.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicePage;
