import React, { useState } from 'react';
import image1 from '../Assets/Architecture_Creations/Villas/image1.jpg';
import image2 from '../Assets/Architecture_Creations/Resorts/image2.jpg';
import image3 from '../Assets/Architecture_Creations/Villas/image2.jpg';
import image5 from '../Assets/Architecture_Creations/Resorts/image1.jpg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './ArchitectureCreations.css';

const Video = ({ videoUrl }) => {
  const handleVideoClick = () => {
    window.open(videoUrl, '_blank');
  };

  return (
    <div className="video-container" onClick={handleVideoClick}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/BVcgWOtJcwc"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="video-frame"
      ></iframe>
      <div className="video-overlay"></div>
    </div>
  );
};

const Header = () => (
  <header className="header">
    <h1>Modern Exteriors</h1>
    <p>
      Our expertise lies in creating stunning exteriors that harmonize with their surroundings while standing out with one-of-a-kind character and elegance. From contemporary classic facades to classic landscapes, our designs are crafted with precision, creativity, and a deep understanding of our clients' visions. We invite you to view our exterior design portfolio below.
    </p>
  </header>
);

const ImageCard = ({ image, title, onClick }) => (
  <div className="card" onClick={onClick}>
    <img src={image} alt={title} className="card-image" />
    <div className="card-title">{title}</div>
  </div>
);

const imagesVillas = [
  { original: image1, thumbnail: image1 },
  { original: image3, thumbnail: image3 },
];

const imagesResorts = [
  { original: image2, thumbnail: image2 },
  { original: image5, thumbnail: image5 },
];

const ImageGrid = ({ onImageClick }) => (
  <div className="image-grid">
    <ImageCard image={image1} title="Villa" onClick={() => onImageClick('villa')} />
    <ImageCard image={image2} title="Resort" onClick={() => onImageClick('resorts')} />
  </div>
);

const ArchitectureCreations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);

  const openLightbox = (gallery) => {
    setSelectedGallery(gallery);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setSelectedGallery(null);
  };

  return (
    <div>
      <Header />
      <Video videoUrl="https://www.youtube.com/watch?v=BVcgWOtJcwc" />
      <div className="message-style"><h2 className="blinking-border">Click on the image to view the Gallery</h2></div>
      <ImageGrid onImageClick={openLightbox} />
      {isOpen && (
        <div className="image-gallery-modal">
          <div className="image-gallery-container">
            <button className="image-gallery-close" onClick={closeLightbox}>
              ×
            </button>
            {selectedGallery === 'villa' && <ImageGallery items={imagesVillas} />}
            {selectedGallery === 'resorts' && <ImageGallery items={imagesResorts} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArchitectureCreations;
